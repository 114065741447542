"use client";

import NextScript from "next/script";
import React from "react";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";

export const TeAds: React.FC = () => {
    const consent = useConsent();
    const isPay = useIsPay();
    try {
        if (!consent || isPay) {
            return null;
        }

        return (
            <>
                <NextScript id="teads-config">
                    {`
                            window.teads_analytics = window.teads_analytics || {};
                            window.teads_analytics.analytics_tag_id = "PUB_23872";
                            window.teads_analytics.share = window.teads_analytics.share || function () {
                                (window.teads_analytics.shared_data = window.teads_analytics.shared_data || []).push(arguments)
                            };
                        `}
                </NextScript>
                <NextScript
                    id="teads-script"
                    src="https://a.teads.tv/analytics/tag.js"
                />
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
