"use client";

import React from "react";
import type { Nullable } from "../../@types/generic";
import { AdScripts } from "../../components/Ad/scripts";
import type { ProgrammaticFrameworks } from "../../components/Ad/types";
import {
    useIsWebview,
    usePageParams,
    usePageType,
} from "../../components/ApplicationState/hooks";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { sendError } from "../../components/ErrorBoundary/helpers";
import { isSpecialCam } from "../../network/matlocq/helpers/livecams";
import { useLivecam } from "../../network/matlocq/queries/Livecam";
import { useSkiArea } from "../../network/matlocq/queries/SkiArea";

type Props = {
    children: React.ReactNode;
};

const RootTemplate: React.FC<Props> = ({ children }) => {
    const pageType = usePageType();
    const pageParams = usePageParams();
    const isWebview = useIsWebview();
    const { data: livecam, isLoading: isLoadingLivecam } = useLivecam(
        pageType?.isLivecamDetailPage && typeof pageParams.id === "string"
            ? pageParams.id
            : null
    );
    const { data: skiArea, isLoading: isLoadingSkiarea } = useSkiArea({
        id: typeof pageParams.id === "string" ? pageParams.id : null,
        enabled: pageType?.isAnySkiAreaDetailPage,
    });

    try {
        if (
            (!isLoadingLivecam && isSpecialCam(livecam ?? undefined)) ||
            (!isLoadingSkiarea && skiArea?.sponsoring.isSponsored) ||
            isWebview
        ) {
            return children;
        }

        let enabledFrameworks: Nullable<Array<ProgrammaticFrameworks>> = null;
        if (pageType?.isHomePage) {
            enabledFrameworks = ["SOM", "CP_AT", "CP_CH", "CP_DE", "gam"];
        } else if (pageType?.isSkiAreasPage) {
            enabledFrameworks = ["SOM", "CP_AT", "CP_CH", "CP_DE"];
        } else if (pageType?.isAnySkiAreaDetailPage) {
            enabledFrameworks = ["SOM", "CP_AT", "CP_CH", "CP_DE", "gam"];
        } else if (
            pageType?.isLivecamLandingPage ||
            pageType?.isLivecamInformationPage
        ) {
            enabledFrameworks = ["CP_AT", "CP_CH", "CP_DE", "SOM"];
        } else if (pageType?.isLivecamDetailPage) {
            enabledFrameworks = ["CP_AT", "CP_CH", "CP_DE", "Outbrain"];
        } else if (pageType?.isAnyForecastPage) {
            enabledFrameworks = [
                "SOM",
                "CP_AT",
                "CP_CH",
                "CP_DE",
                "gam",
                "Outbrain",
            ];
        } else if (pageType?.isAnyHealthPollenDictionaryPage) {
            enabledFrameworks = ["SOM", "CP_AT", "CP_CH", "CP_DE", "gam"];
        }

        return (
            <>
                {children}
                <ErrorBoundary>
                    <AdScripts enabledFrameworks={enabledFrameworks} />
                </ErrorBoundary>
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};

export default RootTemplate;
