import type { Nullable } from "../../@types/generic";

export const formatTemperature = (
    value: Nullable<number> | undefined,
    noDegree = false
): string => {
    if (value === null || value === undefined) {
        return "k.A.";
    }

    // round comma numbers to one digit and leave non-comma numbers as they are
    const valueString = value % 1 === 0 ? value.toString() : value.toFixed(1);

    if (noDegree) {
        return valueString;
    }

    // degree sign
    return `${valueString}${String.fromCharCode(176)}`;
};
