import useSWR, { type SWRResponse } from "swr";
import type { Nullable } from "../../../@types/generic";
import dayjs, { getTimezone, type Dayjs } from "../../../app/common/dayjs";
import { useCountry } from "../../../components/ApplicationState/hooks";
import { QUERY_NAME_FORECAST_WEATHER_NEARBY } from "../../cacheKeys";
import { getForecastWeatherNearby } from "../api/forecastWeatherNearby";
import type { ForecastWeatherNearby } from "../types/forecastWeatherNearby";

type Parameters = {
    latitude: Nullable<number> | undefined;
    longitude: Nullable<number> | undefined;
    from?: Dayjs;
    to?: Dayjs;
    timezone?: Nullable<string> | undefined;
    enabled?: boolean;
};

export const useForecastWeatherNearby = ({
    latitude,
    longitude,
    from,
    to,
    timezone,
    enabled,
}: Parameters): SWRResponse<Nullable<ForecastWeatherNearby>> => {
    const country = useCountry();
    const selectedTimezone = getTimezone(timezone, country);
    const isEnabled =
        ((typeof enabled === "boolean" && enabled) ||
            typeof enabled === "undefined") &&
        typeof latitude === "number" &&
        typeof longitude === "number";

    const { data, isLoading, ...rest } = useSWR<
        Nullable<ForecastWeatherNearby>
    >(
        isEnabled
            ? [QUERY_NAME_FORECAST_WEATHER_NEARBY, latitude, longitude]
            : null,
        () =>
            getForecastWeatherNearby(
                latitude as number,
                longitude as number,
                country
            )
    );

    if (!from && !to) {
        return {
            data,
            isLoading: isLoading || data === undefined,
            ...rest,
        };
    } else {
        let filteredData: Nullable<ForecastWeatherNearby> = null;

        if (data) {
            const filteredItems = data.items.filter((item) => {
                const date = dayjs(item.summary.date)
                    .tz(selectedTimezone)
                    .startOf("day");
                return date.isBetween(
                    dayjs(from).tz(selectedTimezone),
                    dayjs(to).tz(selectedTimezone),
                    "milliseconds",
                    "[]"
                );
            });

            filteredData = { ...data, items: filteredItems };
        }

        return {
            data: filteredData,
            isLoading: isLoading || data === undefined,
            ...rest,
        };
    }
};
