import { useEffect, useState } from "react";
import type { Nullable } from "../../@types/generic";
import type {
    ForecastTrackingParameters,
    GenericTrackingParameters,
} from "../../@types/tracking";
import {
    usePageParams,
    usePageType,
} from "../../components/ApplicationState/hooks";
import { formatTemperature } from "../../helpers/formatters/temperature";
import { numberToString } from "../../helpers/formatters/values";
import { useForecastWeatherNearby } from "../../network/matlocq/queries/ForecastWeatherNearby";
import { useLivecam } from "../../network/matlocq/queries/Livecam";
import { useLocation } from "../../network/matlocq/queries/Location";

export const useTrackingParameter = (): Nullable<
    ForecastTrackingParameters | GenericTrackingParameters
> => {
    const pageParams = usePageParams();
    const pageType = usePageType();
    const { data: location } = useLocation(
        pageType?.isAnyForecastPage && pageParams.code ? pageParams.code : null
    );
    const { data: livecam } = useLivecam(
        pageType?.isLivecamDetailPage && pageParams.id ? pageParams.id : null
    );
    const coordinates = pageType?.isAnyForecastPage
        ? {
              latitude: location?.coordinates.latitude,
              longitude: location?.coordinates.longitude,
          }
        : pageType?.isLivecamDetailPage
          ? {
                latitude: livecam?.coordinates.latitude,
                longitude: livecam?.coordinates.longitude,
            }
          : { latitude: null, longitude: null };
    const { data: forecast, isLoading: isLoadingForecast } =
        useForecastWeatherNearby({
            ...coordinates,
            enabled:
                pageType?.isAnyForecastPage || pageType?.isLivecamDetailPage,
        });

    const [trackingParameter, setTrackingParameter] =
        useState<
            Nullable<ForecastTrackingParameters | GenericTrackingParameters>
        >(null);

    useEffect(() => {
        const todaySummary = forecast?.items[0]?.summary ?? null;
        if (pageType?.isLivecamDetailPage && !isLoadingForecast) {
            setTrackingParameter({
                vhs: "",
                wx_tmax:
                    todaySummary !== null
                        ? formatTemperature(todaySummary.temperature.max, true)
                        : "k.A.",
                wx_tmin:
                    todaySummary !== null
                        ? formatTemperature(todaySummary.temperature.min, true)
                        : "k.A.",
                wx_wx:
                    todaySummary !== null
                        ? numberToString(todaySummary.weather.state)
                        : "",
                wx_cold:
                    todaySummary !== null
                        ? numberToString(todaySummary.weather.state)
                        : "",
                wx_windmax:
                    todaySummary !== null
                        ? numberToString(todaySummary.windchill.max)
                        : "",
                wx_windmin:
                    todaySummary !== null
                        ? numberToString(todaySummary.windchill.min)
                        : "",
                wx_gust:
                    todaySummary !== null
                        ? numberToString(todaySummary.wind.gusts.value)
                        : "",
                w_ra:
                    todaySummary !== null
                        ? numberToString(todaySummary.prec.probability)
                        : "",
            } as GenericTrackingParameters);
        } else if (pageType?.isAnyForecastPage && !isLoadingForecast) {
            let vhs = "";

            if (pageType.isForecastMultiplePage) {
                vhs = "16-Tage Trend";
                if (pageType.isForecastWeekendPage) {
                    vhs = "Wochenende";
                } else if (pageParams.daysMultiple === "7") {
                    vhs = "7-Tage Prognose";
                } else if (pageParams.daysMultiple === "3") {
                    vhs = "3-Tage Übersicht";
                }
            } else if (pageType.isForecastTodayPage) {
                vhs = "heute";
            } else if (pageType.isForecastTomorrowPage) {
                vhs = "Morgen";
            } else {
                vhs = `in ${pageParams.daysSingle ?? "unknown"} Tagen`;
            }

            setTrackingParameter({
                wx_cityname: location?.name ?? "",
                wx_postcode: location?.zipcodes[0] ?? "",
                wx_statecode: location?.admin1.location.code ?? "",
                wx_munic: location?.admin4.code ?? "",
                postcodes: location?.zipcodes ?? [""],
                countryName: location?.country.code ?? "",
                continentName: location?.continent.location.name ?? "",
                vhs,
                wx_tmax:
                    todaySummary !== null
                        ? formatTemperature(todaySummary.temperature.max, true)
                        : "k.A.",
                wx_tmin:
                    todaySummary !== null
                        ? formatTemperature(todaySummary.temperature.min, true)
                        : "k.A.",
                wx_wx:
                    todaySummary !== null
                        ? numberToString(todaySummary.weather.state)
                        : "",
                wx_cold:
                    todaySummary !== null
                        ? numberToString(todaySummary.weather.state)
                        : "",
                wx_windmax:
                    todaySummary !== null
                        ? numberToString(todaySummary.windchill.max)
                        : "",
                wx_windmin:
                    todaySummary !== null
                        ? numberToString(todaySummary.windchill.min)
                        : "",
                wx_gust:
                    todaySummary !== null
                        ? numberToString(todaySummary.wind.gusts.value)
                        : "",
                w_ra:
                    todaySummary !== null
                        ? numberToString(todaySummary.prec.probability)
                        : "",
            } as ForecastTrackingParameters);
        } else {
            setTrackingParameter(null);
        }
    }, [isLoadingForecast, forecast, location, pageType, pageParams]);

    return trackingParameter;
};
