"use client";

import NextScript from "next/script";
import React from "react";
import { pushDataLayer } from "../../../helpers/dataLayer";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";

export const Confiant: React.FC = () => {
    const consent = useConsent();
    const isPay = useIsPay();
    try {
        if (!consent || isPay) {
            return null;
        }

        return (
            <NextScript
                id="confiant"
                src="//cdn.confiant-integrations.net/9cW6UonHtfnRX-HxzwyVTQVZMbA/gpt_and_prebid/config.js"
                onError={(): void => {
                    pushDataLayer({ type: "adBlock", adBlock: "Yes" });
                }}
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
