import type { CountryType, Nullable } from "../../../@types/generic";
import dayjs from "../../../app/common/dayjs";
import { fetcher } from "../index";
import type { ForecastWeatherNearby } from "../types/forecastWeatherNearby";

export const getForecastWeatherNearby = (
    latitude: number,
    longitude: number,
    country: CountryType
): Promise<Nullable<ForecastWeatherNearby>> => {
    if (!latitude || !longitude) {
        return Promise.resolve(null);
    }

    return fetcher<ForecastWeatherNearby>(
        {
            path: `/weather/forecast/${latitude.toString()}/${longitude.toString()}/`,
        },
        {
            country,
        }
    )
        .then((data) => {
            data.items.sort((a, b) => {
                const aDate = dayjs(a.summary.date);
                const bDate = dayjs(b.summary.date);
                return aDate.diff(bDate);
            });
            const sortOrder = ["morning", "afternoon", "evening", "night"];
            data.items.forEach((item) => {
                item.spaces.sort(
                    (a, b) =>
                        sortOrder.indexOf(a.type ?? "") -
                        sortOrder.indexOf(b.type ?? "")
                );
            });
            return data;
        })
        .catch(() => null);
};
