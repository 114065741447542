"use client";

import dynamic from "next/dynamic";
import React from "react";
import type { Nullable } from "../../../@types/generic";
import { useTrackingParameter } from "../../../app/(dach)/hooks";
import { useCountry } from "../../ApplicationState/hooks";
import type { ProgrammaticFrameworks } from "../types";
import { Confiant } from "./Confiant";
import { Outbrain } from "./Outbrain";
import { TeAds } from "./TeAds";

const ClickPerformanceATCH = dynamic(
    () =>
        import("./ClickPerformanceATCH").then(
            (mod) => mod.ClickPerformanceATCH
        ),
    { ssr: false }
);

const ClickPerformanceDE = dynamic(
    () => import("./ClickPerformanceDE").then((mod) => mod.ClickPerformanceDE),
    { ssr: false }
);

const GoogleAdManager = dynamic(
    () => import("./GoogleAdManager").then((mod) => mod.GoogleAdManager),
    { ssr: false }
);

const Som = dynamic(() => import("./Som").then((mod) => mod.Som), {
    ssr: false,
});

type Props = {
    enabledFrameworks: Nullable<Array<ProgrammaticFrameworks>>;
};

export const AdScripts: React.FC<Props> = ({ enabledFrameworks }: Props) => {
    const country = useCountry();
    const trackingParameter = useTrackingParameter();

    if (enabledFrameworks === null) {
        return null;
    }

    return (
        <>
            <TeAds />
            <Confiant />
            {enabledFrameworks.includes("gam") ? <GoogleAdManager /> : null}
            {enabledFrameworks.includes("SOM") && country === "at" ? (
                <Som trackingParameter={trackingParameter} />
            ) : null}
            {enabledFrameworks.includes("CP_AT") && country === "at" ? (
                <ClickPerformanceATCH trackingParameter={trackingParameter} />
            ) : null}
            {enabledFrameworks.includes("CP_CH") && country === "ch" ? (
                <ClickPerformanceATCH trackingParameter={trackingParameter} />
            ) : null}
            {enabledFrameworks.includes("CP_DE") && country === "de" ? (
                <ClickPerformanceDE trackingParameter={trackingParameter} />
            ) : null}
            {enabledFrameworks.includes("Outbrain") ? <Outbrain /> : null}
        </>
    );
};
