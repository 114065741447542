"use client";

import NextScript from "next/script";
import React from "react";
import { sendError } from "../../ErrorBoundary/helpers";

export const Outbrain: React.FC = () => {
    try {
        return (
            <NextScript
                src="https://widgets.outbrain.com/outbrain.js"
                id="outbrain"
                strategy="lazyOnload"
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
